import React from 'react';
import {Link} from 'gatsby';
import {OutboundLink} from 'gatsby-plugin-google-gtag';

import Layout from '../../components/Layout';
import Footer from '../../components/Footer';
import SignUpCTA from '../../components/SignUpCTA';
import Nav from '../../components/Nav';
import AboutPackageContainer from '../../components/AboutPackageContainer';
import BasicPricePackage from '../../components/PricePackages/BasicPricePackage';
import Paragraph from '../../ui/Paragraph';
import {aboutStandardRoute} from '../../routes';
import EnvData from '../../components/EnvData';
import useEnvContext from '../../hooks/useEnvContext';
import {PRIMARY_COLOR, PRIMARY_COLOR_2} from '../../constants';

export default function BasicPage() {
	return (
		<EnvData>
			<ThePage />
		</EnvData>
	);
}

const ThePage = function () {
	const {signUpRoute} = useEnvContext();

	return (
		<Layout title="BASIC paket usluga" description="Saznajte detalje o BASIC paketu usluga, što točno nudi i koje su cijene">
			<Nav background="#008a60" />

			<AboutPackageContainer color1={PRIMARY_COLOR_2} color2={PRIMARY_COLOR}>
				<aside>
					<BasicPricePackage />
				</aside>
				<main>
					<h1>BASIC paket</h1>
					<h2 className="mt-2">Mogućnost unosa 10000 stavki</h2>
					<Paragraph>
						U svim paketima, pa tako i u BASIC paketu usluga, Vam sustav dozvoljava unos 10000 artikala. To je veliki broj i rijetko tko će
						ga doseći tako da bi mi komotno mogli reći i da imate neograničen broj artikala koje možete unijeti, ali, tehnička limitacija na
						količinu sadržaja uvijek postoji, pa tako i kod nas.
					</Paragraph>
					<h2>Neograničen broj skeniranja QR koda</h2>
					<Paragraph>
						Sve QR kodove koje Vam naš sustav dȃ se mogu skenirati neograničen broj puta. Jedino što je bitno je da je Vaša usluga kod nas
						aktivna kako bi QR kodovi nastavili raditi.
					</Paragraph>
					<h2>Označavanje stavke dostupnom, nedostupnom ili popularnom</h2>
					<Paragraph>
						Svaku stavku koju ubacite u svoj cjenik možete označiti popularnom. U tom slučaju, uz stavku će biti oznaka koja će vašim
						gostima skrenuti pozornost na tu stavku u odnosu na ostale.
					</Paragraph>
					<Paragraph>
						U slučaju da nešto iz svog cjenika nemate trenutno dostupno, označite tu stavku nedostupnom. To će ostaviti vašu stavku i dalje
						vidljivu u cjeniku, ali će biti prekrižena što će dati jasnu napomenu vašem gostu da je to nešto što se trenutno ne može
						naručiti.
					</Paragraph>
					<h2>Dva teksta</h2>
					<Paragraph>
						U BASIC paketu možete dodati maksimalno dva tekstualna elementa u svoj cjenik koji nemaju veze sa samim stavkama cjenika. Npr.
						prvi tekstualni element možete postaviti na vrh, a drugi skroz dolje na kraj cjenika. Na vrhu možete napisati bilo kakav
						"dobrodošli" tekst, a ispod cjenika možete napisati obavezan tekst koji bi trebao imati svaki cjenik, tipa,{' '}
						<em>ne točimo alkohol osobama mlađim od 18 godina</em> i slično.
					</Paragraph>
					<h2>Mogućnost isticanja načina plaćanja</h2>
					<Paragraph>
						Svaki cjenik se sastoji od niza elemenata koje ubacujete jedan ispod drugog. Jedan od tih elemenata su ikone za različite načine
						plaćanja, pa tako možete ubaciti ikonu za cash, Visu, MasterCard i slično. Pozicija ovog elementa može biti bilo gdje u cjeniku,
						a element možete ubaciti i više puta.
					</Paragraph>
					<h2>Odabir izgleda cjenika i vizualne prilagodbe</h2>
					<Paragraph>
						U svim paketima možete odabrati themu (gotovi vizualni predložak) koju želite koristiti u svom cjeniku, a određene komponente
						teme možete posebno prilagoditi kako želite. Ne treba Vam nikakvo programersko znanje, niti išta tako da prilagodili izgled.
						Jedino što Vam preporučujemo je da podešavanje teme radite na većem ekranu.
					</Paragraph>
					<h2>Vaši gosti imaju mogućnost pretraživanja cjenika</h2>
					<Paragraph>
						Nakon što Vaši gosti ili kupci skeniraju Vaš QR kod, otvorit će im se lista artikala. S obzirom da su ekrani mobilnih uređaja
						mali, a vaših artikala može biti podosta, gostu je omogućeno brzo pretraživanje cjenika prema unešenoj riječi. Npr. ako vaš gost
						utipka samo slovo <strong>&quot;k&quot;</strong>, odmah će dobiti sve stavke koje počinju sa slovom{' '}
						<strong>&quot;k&quot;</strong>. Ako nudite kavu, kava će vjerojatno biti u samom vrhu rezultata pretraživanja.
					</Paragraph>
					<h2>Cijena</h2>
					<Paragraph>
						Ako plaćate iz mjeseca u mjesec, cijena ovog paketa je 7,95€, a ako platite za cijelu godinu unaprijed, onda je cijena 79,50€
						što je ekvivalent od 6,63€/mj. Sve cijene su izražene s uključenim PDV-om. Plaćanje se uvijek radi za period koji slijedi, tako
						da ugovorna obveza ne postoji.
					</Paragraph>
					<nav>
						<OutboundLink href={signUpRoute()}>Isprobaj odmah</OutboundLink>
						<Link to={aboutStandardRoute()} className="primary">
							STANDARD paket &rarr;
						</Link>
					</nav>
				</main>
			</AboutPackageContainer>

			<SignUpCTA mt="5rem" backgroundColor="#008a60" buttonColor="#31c192" />
			<Footer />
		</Layout>
	);
};
